import {DataTable} from "../../components/data-table.tsx"
import {Heading} from "@/components/tailwind/heading.tsx";
import {Trans} from "react-i18next";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useState} from "react";
import {Divider} from "@/components/tailwind/divider.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {t} from "i18next";
import {orderColumns} from "@/pages/tasks/order-columns.tsx";
import {OrderOverviewItem} from "@/models/order.ts";
import {getOrders} from "@/services/order-services.ts";

export default function OrderListPage() {
    const {token} = useAuth()
    const [orders, setOrders] = useState<OrderOverviewItem[]>([])

    useEffect(() => {
        const ordersFromServer = getOrders(token)

        ordersFromServer
            .then((tasks) => setOrders(tasks as OrderOverviewItem[]))
    }, [])

    return (
        <>
            <Heading level={2}><Trans i18nKey="orderList.mainHeader"/></Heading>
            <div className="container mx-auto py-10">
                <DataTable columns={orderColumns} data={orders}/>
            </div>

            <Divider className="my-5" soft/>

            <section className="flex justify-end gap-4">
                <Button aria-label={t("orderList.newOrderButton.ariaLabel")} href="/orders/new">
                    <Trans i18nKey="orderList.newOrderButton.title"/>
                </Button>
            </section>
        </>
    )
}
