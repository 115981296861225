import {useParams} from "react-router-dom";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useState} from "react";
import {OrderDetails} from "@/models/order.ts";
import {getOrderDetails} from "@/services/order-services.ts";
import {Text} from "@/components/tailwind/text.tsx";
import {Card} from "@/components/ui/card.tsx";
import {Avatar, AvatarFallback} from "@/components/ui/avatar.tsx";
import {
    CalendarIcon,
    ClipboardList,
    Coins,
    FilePenLineIcon,
    FolderInput,
    Hash,
    ListTodo,
    MessageSquare,
    Pencil,
    User,
    XIcon
} from "lucide-react";
import {Heading, Subheading} from "@/components/tailwind/heading.tsx";
import {Trans} from "react-i18next";
import {formatDateString} from "@/lib/utils.ts";
import {DataTable} from "@/components/data-table.tsx";
import {Link} from "@/components/tailwind/link.tsx";
import {Button} from "@/components/tailwind/button.tsx";

export default function OrderDetailsPage() {
    const {orderId} = useParams()
    const {token} = useAuth()
    const [orderData, setOrderData] = useState<OrderDetails>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const task = getOrderDetails(token, orderId!!)

        task
            .then((data) => {
                    setOrderData(data)
                    setIsLoading(false)
                }
            )
    }, [token, orderId])

    if (isLoading) {
        return (
            <Text>Lade Information</Text>
        )
    }

    if (orderData) {
        return (
            <div className="container mx-auto py-6 max-w-4xl space-y-6">
                <Card className="w-full">
                    <div className="p-6">
                        <div className="relative">
                            <div className="flex flex-col md:flex-row md:items-center gap-6">
                                <Avatar className="h-24 w-24">
                                    <AvatarFallback>
                                        <ListTodo size={48}/>
                                    </AvatarFallback>
                                </Avatar>
                                <div className="space-y-2">
                                    <div>
                                        <Heading>Auftrag {orderData.orderNo}</Heading>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>

                <div className="flex flex-col md:flex-row gap-6">
                    <Card className="flex-1">
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    Auftraggeber
                                </div>
                                <button
                                    onClick={() => console.log('Edit clicked')}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div>
                                <div className="flex items-center gap-3">
                                    <User className="w-4 text-gray-500"/>
                                    <Text>{orderData.member.firstName} {orderData.member.lastName}</Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Hash className="w-4 text-gray-500"/>
                                    <Text>Mitgliedsnummer: {orderData.member.memberNo}</Text>
                                </div>
                                {(orderData.member.memberPoints || true) && (
                                    <div className="flex items-center gap-3">
                                        <Coins className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                        <Text><Trans i18nKey={"memberDetails.sections.membership.points"}/>:&nbsp;
                                            {orderData.member.memberPoints?.points ?? '0'}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>

                    <Card className="flex-1">
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    Helfer
                                </div>
                                <button
                                    onClick={() => console.log('Edit clicked')}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div>
                                <div className="flex items-center gap-3">
                                    <User className="w-4 text-gray-500"/>
                                    <Text>{orderData.helper.firstName} {orderData.helper.lastName}</Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Hash className="w-4 text-gray-500"/>
                                    <Text>Mitgliedsnummer: {orderData.helper.memberNo}</Text>
                                </div>
                            </div>
                        </div>
                    </Card>

                </div>

                <Card>
                    <div className="p-6">
                        <div className="relative mb-6">
                            <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                <Trans i18nKey={"orderDetails.sections.meta.heading"}/>
                            </div>
                            <button
                                onClick={() => console.log('Edit clicked')}
                                className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                aria-label="Anmerkungen bearbeiten"
                            >
                                <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                            </button>
                        </div>

                        <div className="flex space-x-6">
                            <div className="flex-1 space-y-6">
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium">
                                        <FolderInput className="h-4 w-4"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"orderDetails.sections.meta.submissionChannel"}/></Subheading>
                                    </div>
                                    {orderData.submissionChannel.name ? (
                                        <Text className="pl-6 whitespace-pre-wrap">
                                            {orderData.submissionChannel.name}
                                        </Text>
                                    ) : (
                                        <Text className="italic pl-6">
                                            <Trans i18nKey={"orderDetails.sections.meta.noComments"}/>
                                        </Text>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium">
                                        <ClipboardList className="h-4 w-4"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"orderDetails.sections.meta.service"}/></Subheading>
                                    </div>
                                    {orderData.submissionChannel.name ? (
                                        <Text className="pl-6 whitespace-pre-wrap">
                                            {orderData.service.name}
                                        </Text>
                                    ) : (
                                        <Text className="italic pl-6">
                                            <Trans i18nKey={"orderDetails.sections.meta.noComments"}/>
                                        </Text>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium">
                                        <CalendarIcon className="h-4 w-4"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"orderDetails.sections.meta.orderDate"}/></Subheading>
                                    </div>
                                    <Text className="pl-6 whitespace-pre-wrap">
                                        {formatDateString(orderData.orderDate)}
                                    </Text>
                                </div>
                            </div>

                            <div className="flex-1 space-y-2">
                                <div className="flex items-center gap-2 text-sm font-medium">
                                    <MessageSquare className="h-4 w-4"/>
                                    <Subheading level={3}><Trans
                                        i18nKey={"orderDetails.sections.meta.comments"}/></Subheading>
                                </div>
                                {orderData.comment ? (
                                    <Text className="whitespace-pre-wrap">
                                        {orderData.comment}
                                    </Text>
                                ) : (
                                    <Text className="italic">
                                        <Trans i18nKey={"orderDetails.sections.meta.noComments"}/>
                                    </Text>
                                )}
                            </div>
                        </div>
                    </div>
                </Card>

                <Card>
                    <div className="p-6">
                        <div className="relative mb-6">
                            <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                <Trans i18nKey={"orderDetails.sections.items.heading"}/>
                            </div>
                        </div>

                        <DataTable
                            columns={[
                                {
                                    header: 'Datum',
                                    accessorKey: 'date',
                                    cell: ({row}) => {
                                        return (<Text>{formatDateString(row.original.date)}</Text>)
                                    }
                                },
                                {header: 'Startzeit', accessorKey: 'startTime'},
                                {header: 'Endzeit', accessorKey: 'endTime'},
                                {header: 'Preis in Punkten', accessorKey: 'priceInPoints'},
                                {header: 'Preis in EUR', accessorKey: 'priceInFiat'},
                                {
                                    header: 'Erstellt am',
                                    accessorKey: 'createdAt',
                                    cell: ({row}) => {
                                        return (<Text>{formatDateString(row.original.createdAt)}</Text>)
                                    }
                                },
                                {
                                    header: 'Geändert am',
                                    accessorKey: 'updatedAt',
                                    cell: ({row}) => {
                                        return (
                                            <Text>
                                                {row.original.updatedAt ? formatDateString(row.original.updatedAt) : 'N/A'}
                                            </Text>)
                                    }
                                },
                                {
                                    header: 'Aktionen', cell: () => {
                                        return (
                                            <div className="flex space-x-1">
                                                <Link href={"#"}><FilePenLineIcon/></Link>
                                                <Link href={"#"}><XIcon/></Link>
                                            </div>
                                        )
                                    }
                                },
                            ]}
                            data={orderData.items}
                        />

                        <div className="flex justify-end mt-4">
                            <Button>Hinzufügen</Button>
                        </div>
                    </div>
                </Card>

                <div className="text-xs text-gray-500 text-right mt-4">
                    <p>angelegt am: {formatDateString(orderData.createdAt)}</p>
                    <p>zuletzt geändert am: {orderData.updatedAt ? formatDateString(orderData.updatedAt) : 'N/A'}</p>
                </div>
            </div>
        )
    }
}
