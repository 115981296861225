import {Text} from "@/components/tailwind/text"
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "@/context/use-auth.tsx";
import {CurrentPointsDTO, deleteMemberData, getMemberData} from "@/services/member-services.ts";
import {Member} from "@/models/member.ts";
import {isDefined} from "@/helpers/sanitizer.ts";
import {Button} from "@/components/tailwind/button.tsx";
import {Divider} from "@/components/tailwind/divider.tsx";
import {Card} from "@/components/ui/card";
import {Avatar, AvatarFallback} from "@/components/ui/avatar";
import {
    Activity,
    Building,
    Cake,
    Clock,
    Coins,
    Hash,
    Mail,
    MapPin,
    MessageSquare,
    Pencil,
    Phone,
    User,
    Users
} from 'lucide-react';
import {Heading, Subheading} from "@/components/tailwind/heading.tsx";
import {Link} from "@/components/tailwind/link.tsx";
import {differenceInYears, format, parseISO} from "date-fns";
import MemberActionsButton from "@/components/member-actions-button.tsx";

export default function MemberDetailsPage() {
    const {t} = useTranslation()
    const {memberId} = useParams()
    const {token} = useAuth()
    const [memberData, setMemberData] = useState<Member>()
    const [memberDataRetrievalError, setMemberDataRetrievalError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const navigate = useNavigate()

    const handleDeleteMember = () => {
        const deleteMember = deleteMemberData(token, memberId)

        deleteMember
            .then(() => {
                navigate("/members")
            })
            .catch((errorDetails) => {
                console.warn("Unable to delete member data. Error details: ", errorDetails)
            })
    };

    const handleAddPoints = (pointsAdded: CurrentPointsDTO) => {
        setMemberData(prevMemberData => {
            if (!prevMemberData) return prevMemberData;

            return {
                ...prevMemberData,
                memberPoints: {
                    ...prevMemberData.memberPoints,
                    points: pointsAdded.points,
                    lastUpdated: pointsAdded.statusFrom
                }
            };
        });
    };

    useEffect(() => {
        const memberDetails = getMemberData(token, memberId)

        memberDetails
            .then((memberData) => {

                if (isDefined<Member>(memberData)) {
                    setMemberData(memberData)
                } else {
                    setMemberDataRetrievalError(true)
                }
            })
            .catch((errorDetails) => {
                console.warn("Unable to retrieve member data. Error details: ", errorDetails)
                setMemberDataRetrievalError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })

    }, [token, memberId])


    if (isLoading) {
        return (
            <Text>Lade Information</Text>
        )
    }

    if (memberDataRetrievalError) {
        return (
            <Text>Konnte Daten nicht abrufen</Text>
        )
    }

    if (memberData) {
        let companyDisplayName: string = ''
        let displayCompanyDetails: boolean = false
        if (memberData.companyName) {
            companyDisplayName = t("memberDetails.companyPrefix") + ` ${memberData.companyName}`
        }

        let memberDisplayName: string = ''
        if (memberData.firstName && memberData.lastName) {
            if (memberData.salutation) {
                memberDisplayName = `${memberData.salutation.value} `
            }
            memberDisplayName += `${memberData.firstName} ${memberData.lastName}`

            if (companyDisplayName) {
                displayCompanyDetails = true
            }
        } else if (companyDisplayName) {
            memberDisplayName = companyDisplayName
        } else {
            // This should never happen (tm)
            memberDisplayName = t("memberDetails.unknownMember")
        }

        let memberAddress = ''
        if (memberData.address) {
            memberAddress = `${memberData.address.street} ${memberData.address.houseNumber}, ` +
                `${memberData.address.postcode} ${memberData.address.city}`
        }

        function formatDate(dateString: string) {
            return format(parseISO(dateString), 'dd.MM.yyyy')
        }

        function calculateAge(birthDate: string | undefined) {
            if (!birthDate) return null;
            const date = parseISO(birthDate);
            return differenceInYears(new Date(), date);
        }

        const age = calculateAge(memberData.dateOfBirth)

        return (

            <>
                <div className="container mx-auto py-6 max-w-4xl space-y-6">
                    <div className={"w-full text-right"}>
                        <MemberActionsButton onAddPoints={handleAddPoints}
                                             onDelete={handleDeleteMember}
                                             memberData={memberData}

                        />

                    </div>
                    <Card className="w-full">
                        <div className="p-6">
                            <div className="relative">
                                <div className="flex flex-col md:flex-row md:items-center gap-6">
                                    <Avatar className="h-24 w-24">
                                        <AvatarFallback>
                                            <User size={48}/>
                                        </AvatarFallback>
                                    </Avatar>
                                    <div className="space-y-2">
                                        <div>
                                            <Heading>{memberDisplayName}</Heading>
                                        </div>
                                        <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">

                                            <div className="flex items-center gap-1">
                                                <Cake className="h-4 w-4"/>
                                                {memberData.dateOfBirth ?
                                                    <Text>
                                                        {formatDate(memberData.dateOfBirth)}
                                                    </Text> :
                                                    <Text
                                                        className={"italic"}>{t("memberDetails.unknown-detail")}</Text>
                                                }
                                                {age && (
                                                    <>
                                                        <span className="text-gray-300 dark:text-gray-600 mx-1">•</span>
                                                        <Text>{age} <Trans
                                                            i18nKey={"memberDetails.sections.main.ageQuantifier"}/>
                                                        </Text>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => console.log('Edit clicked')}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.contacts.heading"}/>
                                </div>
                                <button
                                    onClick={() => console.log('Edit clicked')}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="space-y-6">
                                <div className="flex items-center gap-3">
                                    <Mail className="h-4 w-4 text-gray-500"/>
                                    {memberData.email ?
                                        <Link type={"email"} href={`mailto:${memberData.email}`}>
                                            <Text>{memberData.email}</Text>
                                        </Link> :
                                        <Text className={"italic"}>{t("memberDetails.unknown-detail")}</Text>
                                    }
                                </div>
                                {memberData.phoneNumbers && memberData.phoneNumbers.length > 0 ?
                                    memberData.phoneNumbers?.map((phoneNumber, index) =>
                                        (
                                            <div className="flex items-center gap-3" key={index}>
                                                <Phone className="h-4 w-4 text-gray-500"/>
                                                <Text>{phoneNumber.number}</Text>
                                            </div>
                                        )
                                    ) : (
                                        <div className="flex items-center gap-3">
                                            <Phone className="h-4 w-4 text-gray-500"/>
                                            <Text className={"italic"}>
                                                <Trans i18nKey={"memberDetails.unknown-detail"}/>
                                            </Text>
                                        </div>
                                    )
                                }
                                <div className="flex items-center gap-3">
                                    <MapPin className="h-4 w-4 text-gray-500"/>
                                    {memberAddress ?
                                        <Text>{memberAddress}</Text> :
                                        <Text className={"italic"}>{t("memberDetails.unknown-detail")}</Text>
                                    }
                                </div>
                                {displayCompanyDetails && (
                                    <div className="flex items-center gap-3">
                                        <Building className="h-4 w-4 text-gray-500"/>
                                        <Text>{companyDisplayName}</Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.membership.heading"}/>
                                </div>
                                <button
                                    onClick={() => console.log('Edit clicked')}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="space-y-6">
                                <div className="flex items-center gap-3">
                                    <Hash className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.membership.memberNo"}/>:&nbsp;
                                        {memberData.memberNo}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Users className="h-4 w-4 text-gray-500"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.membership.memberSince"}/>: {
                                        memberData.membershipStartDate ?
                                            format(parseISO(memberData.membershipStartDate), 'dd.MM.yyyy') :
                                            <i><Trans i18nKey={"memberDetails.unknown-detail"}/></i>
                                    }</Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Activity className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.membership.status"}/>:&nbsp;
                                        {memberData.membershipStatus.value}
                                    </Text>
                                </div>
                                {(memberData.memberPoints || true) && (
                                    <div className="flex items-center gap-3">
                                        <Coins className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                        <Text><Trans i18nKey={"memberDetails.sections.membership.points"}/>:&nbsp;
                                            {memberData.memberPoints?.points ?? '0'}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.comments.heading"}/>
                                </div>
                                <button
                                    onClick={() => console.log('Edit clicked')}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium ">
                                        <MessageSquare className="h-4 w-4"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"memberDetails.sections.comments.general"}/></Subheading>
                                    </div>
                                    {memberData.generalComments ? (
                                        <Text className="pl-6 whitespace-pre-wrap">
                                            {memberData.generalComments}
                                        </Text>
                                    ) : (
                                        <Text className="italic pl-6">
                                            <Trans i18nKey={"memberDetails.sections.comments.noComments"}/>
                                        </Text>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium">
                                        <Clock className="h-4 w-4"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"memberDetails.sections.comments.availability"}/></Subheading>
                                    </div>
                                    {memberData.availabilityComments ? (
                                        <Text className="pl-6 whitespace-pre-wrap">
                                            {memberData.availabilityComments}
                                        </Text>
                                    ) : (
                                        <Text className="italic pl-6">
                                            <Trans i18nKey={"memberDetails.sections.comments.noComments"}/>
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>

                </div>

                <Divider className="my-10" soft/>

                <section className="flex justify-end gap-4">
                    <Button aria-label={t("memberDetails.buttons.back.ariaLabel")} href="/members"
                            role="navigation"><Trans i18nKey="memberDetails.buttons.back.text"/></Button>
                </section>

            </>

        )
    }

}
