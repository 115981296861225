import {Dialog} from '@headlessui/react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Member} from "@/models/member.ts";
import {Button} from "@/components/tailwind/button.tsx";
import {Input} from "@/components/tailwind/input.tsx";
import {Subheading} from "@/components/tailwind/heading.tsx";

interface AddMemberPointsDialogProps {
    memberData: Member;
    isOpen: boolean;
    onClose: () => void;
    onSubmission: (data: FormData) => void;
}

interface FormData {
    points: number;
    description: string;
}

const validationSchema = yup.object().shape({
    points: yup
        .number()
        .min(1, "Es kann minimum 1 Punkt gutgeschrieben werden")
        .required('Bitte geben Sie eine Zahl ein')
        .typeError('Der Wert muss eine Zahl sein'),
    description: yup
        .string()
        .required('Bitte geben Sie eine Beschreibung ein')
        .min(3, 'Die Beschreibung muss mindestens 3 Zeichen lang sein')
        .max(100, 'Die Beschreibung darf maximal 100 Zeichen lang sein')
});

function AddMemberPointsDialog({memberData, isOpen, onClose, onSubmission}: AddMemberPointsDialogProps) {
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            points: 0,
            description: ''
        }
    });

    function onSubmit(data: FormData) {
        reset();
        onSubmission(data);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/40 dark:bg-black/60" aria-hidden="true"/>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="mx-auto max-w-lg rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl">
                    <div>
                        {/* FIXME Correct headline generation missing */}
                        <Subheading level={3} className="leading-6 mb-4">
                            Neue Eingabe für {memberData.firstName} {memberData.lastName}
                        </Subheading>

                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                            <div>
                                <label
                                    htmlFor="points"
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                                >
                                    Zahl
                                </label>
                                <div className="mt-2">
                                    <Input
                                        type="points"
                                        {...register('points')}
                                    />
                                    {errors.points && (
                                        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                                            {errors.points.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                                >
                                    Beschreibung
                                </label>
                                <div className="mt-2">
                                    <Input
                                        type="text"
                                        {...register('description')}
                                        placeholder="Begründung für Punkte Anpassung"
                                    />
                                    {errors.description && (
                                        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                                            {errors.description.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="mt-6 flex justify-end gap-3">
                                <Button
                                    type="button"
                                    onClick={onClose}
                                >
                                    Abbrechen
                                </Button>
                                <Button type="submit">
                                    Speichern
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default AddMemberPointsDialog;
