import React from 'react';

type DeleteConfirmationProps = {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    itemName?: string; // Optional: Name des zu löschenden Elements
};

export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
                                                                          isOpen,
                                                                          onConfirm,
                                                                          onCancel,
                                                                          itemName = 'Item',
                                                                      }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                <h2 className="text-lg font-semibold text-gray-800">Löschen bestätigen</h2>
                <p className="text-gray-600 mt-2">
                    Sind Sie sicher, dass Sie <span className="font-semibold">{itemName}</span> löschen möchten? Diese
                    Aktion kann nicht rückgängig gemacht werden.
                </p>
                <div className="flex justify-end gap-4 mt-6">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md"
                    >
                        Abbrechen
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md"
                    >
                        Löschen
                    </button>
                </div>
            </div>
        </div>
    );
};
