import {OrderDetails, OrderOverviewItem} from "@/models/order.ts";

const NBH_ORDERS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/orders`

// TODO replace with backend data
const mockOrders: OrderOverviewItem[] = [
    {
        id: "550e8400-e29b-41d4-a716-446655440000",
        orderNo: "1/2025",
        memberName: "Guy Incognito",
        helperName: "Homer Simpson",
        service: "Computersupport",
        orderDate: "2025-02-02",
        comment: "Handy reparieren",
        status: "open"
    },
    {
        id: "716b3528-1785-4d46-a251-24f3213722d9",
        orderNo: "2/2025",
        memberName: "Abe Simpson",
        helperName: "Lisa Simpson",
        service: "Fahrtdienst",
        orderDate: "2025-03-15",
        comment: "Zum Hausarzt begleiten",
        status: "done"
    },
    {
        id: "d632039a-813c-4191-9bfd-7e8b6e3e5666",
        orderNo: "3/2025",
        memberName: "Nelson Muntz",
        helperName: "Lisa Simpson",
        service: "Lernhilfe",
        orderDate: "2025-01-30",
        comment: "Vorbereitung auf Matheprüfung",
        status: "canceled"
    }
]


const mockOrderDetails:OrderDetails[] = [
    {
        id: "550e8400-e29b-41d4-a716-446655440000",
        orderNo: "1/2025",
        member: {
            id: "550e8400-e29b-41d4-a716-446655440000",
            memberNo: 1,
            firstName: "Guy",
            lastName: "Incognito",
            membershipStatus: {
                id: "550e8400-e29b-41d4-a716-446655440000",
                value: "active",
            },
        },
        helper: {
            id: "550e8400-e29b-41d4-a716-446655440000",
            memberNo: 2,
            firstName: "Homer",
            lastName: "Simpson",
            membershipStatus: {
                id: "550e8400-e29b-41d4-a716-446655440000",
                value: "active",
            },
        },
        service: {
            id: "550e8400-e29b-41d4-a716-446655440000",
            name: "Computersupport",
            description: "Hilfe bei technischen Problemen",
        },
        submissionChannel: {
            id: "550e8400-e29b-41d4-a716-446655440000",
            name: "Telefon",
            description: "Telefonanruf",
        },
        items: [
            {
                id: "550e8400-e29b-41d4-a716-446655440000",
                date: "2025-02-02",
                startTime: "10:00",
                endTime: "12:00",
                priceInPoints: 4,
                priceInFiat: 4,
                createdAt: "2025-01-01",
            },
        ],
        orderDate: "2025-02-02",
        comment: "Handy reparieren",
        createdAt: "2025-01-01",
    }
]

export async function getOrders(token: string | null) {
    console.log("Token was", token)
    return Promise.resolve(mockOrders)
}

export async function getOrderDetails(token: string | null, orderId: string) {
    console.log("Token was", token)
    console.log("Order ID was", orderId)
    console.log("Endpoint would have been", `${NBH_ORDERS_BASE_URL}/${orderId}`)
    return Promise.resolve(mockOrderDetails[0])
}
