import DOMPurify from "dompurify";

export function sanitizeText(input: string | undefined): string | undefined {
    if (isDefined(input))
        return DOMPurify.sanitize(input!!)
    return input
}

export function isDefined<T>(value: T | void): value is T {
    return value !== undefined
}
