import {handleResponse} from "@/services/service-helpers.ts";
import {CreateMemberDTO, Member, MemberFormOptions} from "@/models/member.ts";

const NBH_MEMBERS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/members`
const NBH_MEMBER_OPTIONS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/options/memberForm`

export async function getMembers(token: string) {
    const response = await fetch(NBH_MEMBERS_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<Member[]>(response)

}

// TODO: Use the backend, once we have the data there
export async function getMemberFormOptions(token: string) {
    const response = await fetch(NBH_MEMBER_OPTIONS_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<MemberFormOptions>(response)
}

export async function createNewMember(token: string, memberData: CreateMemberDTO) {
    const response = await fetch(NBH_MEMBERS_BASE_URL, {
        method: "POST",
        headers:
            new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }),
        body: JSON.stringify(memberData)
    })

    return handleResponse<Member>(response)
}

export async function getMemberData(token: string | null, memberId: string | undefined): Promise<Member | void> {
    if (!Boolean(token)) {
        console.error("No token provided for member data retrieval.")
        return Promise.reject("No token provided")
    }
    if (!Boolean(memberId)) {
        console.error("No memberId provided for member data retrieval.")
        return Promise.reject("No memberId provided.")
    }

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<Member>(response)
}

export async function deleteMemberData(token: string | null, memberId: string | undefined): Promise<void> {
    if (!Boolean(token)) {
        console.error("No token provided for member data deletion.")
        return Promise.reject("No token provided")
    }
    if (!Boolean(memberId)) {
        console.error("No memberId provided for member data deletion.")
        return Promise.reject("No memberId provided.")
    }

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}`, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<void>(response)
}

export type AddPointsDTO = {
    memberId: string,
    points: number,
    description: string
}

export type CurrentPointsDTO = {
    memberId: string,
    points: number,
    statusFrom: string
}
export async function addPointsToMember(token: string | null, pointsData: AddPointsDTO): Promise<CurrentPointsDTO | void> {
    if (!Boolean(token)) {
        console.error("No token provided while trying to add points to member.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${pointsData.memberId}/points`, {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(pointsData)
    });

    return handleResponse<CurrentPointsDTO>(response)
}
