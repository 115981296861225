import * as Yup from 'yup';
import {emptyStringToUndefined, optionalGermanPhoneNumberString, optionalString} from "@/helpers/yup-helpers.ts";

export function validationSchema() {
    return Yup.object().shape({
        id:
            Yup.string()
                .uuid("memberForm.errors.invalid-id")
                .required("memberForm.errors.missing-id"),
        title: optionalString(),
        firstName: Yup.string()
            .transform(emptyStringToUndefined)
            .when('companyName', {
                is: (companyName: string) => !Boolean(companyName),
                then: schema => schema.required("memberForm.errors.first-name-required"),
                otherwise: schema => schema.optional()
            }),
        lastName: Yup.string()
            .transform(emptyStringToUndefined)
            .when('companyName', {
                is: (companyName: string) => !Boolean(companyName),
                then: schema => schema.required("memberForm.errors.last-name-required"),
                otherwise: schema => schema.optional()
            }),
        companyName: optionalString(),
        email: optionalString()
            .email("memberForm.errors.invalid-email-address"),
        address: Yup.object({
            street: optionalString(),
            houseNo: optionalString(),
            postcode: optionalString()
                .matches(/^\d{5}$/, "memberForm.errors.address-postcode-invalid-format"),
            city: optionalString(),
        }).test('address-fields-validation', "memberForm.errors.address-fields-required",
            function (value) {
                if (value == undefined)
                    return true;

                const addressFieldCount = 4;
                const hasSomeValues = Object.values(value).some(Boolean);
                const allAvailableValuesSet = Object.values(value).some(Boolean);
                const hasExpectedKeyCount = Object.keys(value).length === addressFieldCount;
                if (hasSomeValues && (!allAvailableValuesSet || !hasExpectedKeyCount)) {
                    return this.createError({
                        path: `${this.path}`,
                        message: "memberForm.errors.address-incomplete"
                    });
                }
                return true;
            }).defined().optional(),
        membershipStart: Yup.date()
            .typeError("memberForm.errors.invalid-membership-start-date-format")
            .optional(),
        membershipStatus: Yup.object()
            .shape({
                id: Yup.string()
                    .uuid("memberForm.errors.invalid-membership-status")
                    .required(),
                value: Yup.string()
                    .required()
            })
            .required("memberForm.errors.membership-status-required"),
        birthday: Yup.date()
            .typeError("invalid-birthday-date-format")
            .max(new Date(), "memberForm.errors.birthday-needs-to-be-before-today")
            .optional(),
        phone: Yup.object()
            .transform((numbers) => (numbers && Object.values(numbers).every(Boolean)) ? numbers : undefined)
            .shape({
                landline: optionalGermanPhoneNumberString("memberForm.errors.invalid-landline"),
                business: optionalGermanPhoneNumberString("memberForm.errors.invalid-business"),
                mobile: optionalGermanPhoneNumberString("memberForm.errors.invalid-mobile"),
                fax: optionalGermanPhoneNumberString("memberForm.errors.invalid-fax"),
            })
            .defined()
            .optional(),
        comments: Yup.object()
            .shape({
                general: optionalString(),
                availability: optionalString()
            })
            .defined()
            .optional(),
    }).test(
        "company-name-required-if-no-full-name-given",
        "company-name-required-if-no-full-name-given",
        function (value) {
            const fullNameGiven = (Boolean(value.firstName) && Boolean(value.lastName));
            const companyNameExists = Boolean(value.companyName);
            if (!(fullNameGiven || companyNameExists)) {
                return this.createError({
                    path: 'companyName',
                    message: "memberForm.errors.company-name-required"
                });
            }
            return true;
        })
        .test(
            "generic-error-message-for-empty-full-name-and-company",
            "memberForm.errors.at-least-full-name-or-company",
            function (value) {
                const fullNameGiven = (Boolean(value.firstName) && Boolean(value.lastName));
                const companyNameExists = Boolean(value.companyName);
                if (!(fullNameGiven || companyNameExists)) {
                    return this.createError({
                        path: "names",
                        message: "memberForm.errors.at-least-full-name-or-company"
                    });
                }
                return true;
            })
        .test(
            "membership-must-start-after-birth",
            "membership-must-start-after-birth",
            function (value) {
                if (value.birthday === undefined || value.membershipStart === undefined) return true;
                if (value.membershipStart < value.birthday) {
                    return this.createError({
                        path: "membershipStart",
                        message: "memberForm.errors.membership-must-start-after-birth"
                    });
                }
                return true;
            });
}
