import i18n from 'i18next'
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "de",
        interpolation: {
            escapeValue: false // react escapes by default
        },
        resources: {
            de: {
                translation: {
                    "error": {
                        404: {
                            "headline": "Seite nicht gefunden",
                            "text": "Die angefragte Seite existiert nicht.",
                            "backLink": "Hier geht zurück zur Startseite",
                        },
                    },
                    "table": {
                        "no-data": "Keine Einträge"
                    },
                    "menu": {
                        "navbar": {
                            "home": {
                                "label": "Start"
                            },
                            "adminSection": {
                                "ariaLabel": "Administrationsbereich"
                            },
                            "membersSection": {
                                "ariaLabel": "Mitgliederverwaltung"
                            },
                            "orders": {
                                "ariaLabel": "Aufträge verwalten",
                                "label": "Aufträge"
                            },
                            "users": {
                                "label": "Benutzer verwalten"
                            },
                            "members": {
                                "label": "Mitglieder"
                            }
                        },
                        "sidebar": {
                            "admin": {
                                "heading": "Administration",
                                "users": {
                                    "label": "Benutzer verwalten"
                                },
                            },
                            "members": {
                                "heading": "Mitgliederverwaltung",
                                "overview": {
                                    "label": "Mitgliederübersicht"
                                }
                            },
                            "orders": {
                                "heading": "Auftragsverwaltung",
                                "overview": {
                                    "label": "Auftragsübersicht"
                                }
                            }
                        },
                        "login": {
                            "ariaLabel": "Führt zum Anmeldebildschirm",
                            "text": "Anmelden"
                        },
                        "myAccount": {
                            "account": {
                                "dropdownLabel": "Mein Nutzerkonto",
                                "ariaLabel": "Link zur Verwaltung meines Nutzerkontos"
                            },
                            "logout": {
                                "dropdownLabel": "Abmelden",
                                "ariaLabel": "Aktuelle Nutzersession beenden"
                            },
                        },
                    },
                    "login": {
                        "headline": "Anmelden",
                        "form": {
                            "username": {
                                "subheading": "Nutzername",
                                "text": "Geben Sie hier den Ihnen bekannten Nutzernamen ein",
                                "ariaLabel": "Ihr Nutzername"
                            },
                            "password": {
                                "subheading": "Passwort",
                                "text": "Geben Sie hier ihr Passwort ein",
                                "ariaLabel": "Ihr Passwort"
                            },
                            "submit": {
                                "label": "Absenden",
                                "ariaLabel": "Anmeldeformular absenden"
                            }
                        },
                        "errors": {
                            "username-empty": "Nutzername darf nicht leer sein.",
                            "password-empty": "Passwort darf nicht leer sein.",
                            "login-failed": "Nutzername oder Passwort sind nicht korrekt.",
                            "unknown-username-issue": "Bitte prüfen Sie Ihre Eingabe für den Nutzernamen.",
                            "unknown-password-issue": "Bitte prüfen Sie Ihre Eingabe für das Password.",
                            "unknown-server-issue": "Login auf Serverseite fehlgeschlagen. Bitte später noch einmal probieren."
                        }
                    },
                    "userList": {
                        "mainHeader": "Benutzerübersicht",
                        "table": {
                            "headers": {
                                "name": "Name",
                                "username": "Nutzername",
                                "role": "Rolle",
                                "active": "Aktiv"
                            },
                            "roleColumn": {
                                "USER": "Nutzer",
                                "ADMIN": "Administrator"
                            },
                            "activeColumn": {
                                "true": "Ja",
                                "false": "Nein"
                            }
                        },
                        "newUserButton": {
                            "ariaLabel": "Neuen Nutzer hinzufügen",
                            "title": "Neuer Nutzer"
                        }
                    },
                    "userForm": {
                        "mainHeader": {
                            "new": "Nutzer anlegen",
                            "edit": "Nutzer editieren"
                        },
                        "fields": {
                            "name": {
                                "subheading": "Name",
                                "text": "Name der nutzenden Person",
                                "placeholder": "Alex Muster",

                            },
                            "username": {
                                "subheading": "Nutzername",
                                "text": "Name, mit dem sich die Person anmeldet",
                                "placeholder": "alex.muster"
                            },
                            "password": {
                                "subheading": "Passwort",
                                "text": "Passwort, mit dem sich die Person authentifiziert.",
                                "additionalText": "Mindestens {{ minLength }} Zeichen",
                                "placeholder": "*******"
                            },
                            "confirmPassword": {
                                "subheading": "Passwort wiederholen",
                                "text": "Bitte Passwort hier noch einmal wiederholen.",
                                "additionalText": "Mindestens {{ minLength }} Zeichen",
                                "placeholder": "*******"
                            },
                            "role": {
                                "subheading": "Benutzerrolle",
                                "text": "\"Nutzer\" dürfen das System normal nutzen. \"Administratoren\" dürfen das System auch anpassen",
                                "options": {
                                    "USER": "Nutzer",
                                    "ADMIN": "Administrator"
                                }
                            },
                            "active": {
                                "subheading": "Nutzer aktiv / inaktiv setzen",
                                "text": "Aktive Nutzer dürfen sich anmelden und das System entsprechend ihrer Rolle benutzen.",
                                "options": {
                                    "USER": "Nutzer",
                                    "ADMIN": "Administrator"
                                },
                                "checkbox": {
                                    "active": "Nutzer ist aktiv",
                                    "inactive": "Nutzer ist inaktiv"
                                }
                            },
                        },
                        "errors": {
                            "invalid-id": "Ungültige ID",
                            "missing-id": 'Fehlende ID. Bitte wenden Sie sich an den Administrator.',
                            "missing-username": 'Nutzername darf nicht leer sein',
                            "username-already-exists": "Der Nutzername wird bereits verwendet.",
                            "password-min-length": "Das Password muss mindestens {{minLength}} Zeichen enthalten.",
                            "missing-password": "Es muss ein Passwort verwendet werden.",
                            "missing-confirm-password": "Bitte Passwort wiederholen.",
                            "password-mismatch": "Passwörter müssen übereinstimmen.",
                            "missing-role": "Bitte wählen Sie eine Rolle aus.",
                            "invalid-role": "Unbekannte Rolle ausgewählt.",
                            "missing-name": "Bitte geben Sie den Namen der benutzenden Person an.",
                            "unknown-id-issue": "Es ist ein unbekannter Fehler mit der Nutzer-ID aufgetreten.",
                            "unknown-name-issue": "Bitte Namen überprüfen.",
                            "unknown-username-issue": "Bitte Nutzernamen überprüfen.",
                            "unknown-password-issue": "Bitte Passwort überprüfen.",
                            "unknown-role-issue": "Bitte Rolle überprüfen.",
                            "unknown-active-issue": "Bitte Eingabe, ob Nutzer aktiv ist überprüfen.",
                            "unknown-server-issue": "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",

                            "section": {
                                "ariaLabel": "Auflistung von Fehlern, die nicht direkt zu Feldern zuordenbar sind"
                            }
                        },
                        "buttons": {
                            "submit": {
                                "ariaLabel": "Formular absenden und Daten speichern",
                                "text": "Speichern"
                            },
                            "cancel": {
                                "ariaLabel": "Abbrechen und zurück zur Übersicht",
                                "text": "Zurück"
                            },
                            "delete": {
                                "ariaLabel": "Nutzer löschen",
                                "text": "Löschen"
                            },
                            "changePassword": {
                                "subheading": "Passwort ändern",
                                "description": "Passwort für Nutzer ändern",
                                "text": "Passwort ändern",
                            }
                        },
                        "dialog": {
                            "delete": {
                                "title": "Nutzer löschen",
                                "description": "Soll der Nutzer {{name}} mit dem Nutzernamen {{username}} wirklich gelöscht werden?"
                            }
                        }

                    },
                    "memberList": {
                        "mainHeader": "Mitgliederverwaltung",
                        "companyPrefix": "Firma",
                        "table": {
                            "headers": {
                                "memberNo": "Mitglieds-Nr.",
                                "name": "Name",
                                "phoneNumbers": "Rufnummern",
                                "address": "Adresse",
                                "status": "Aktiv / Passiv"
                            },
                            "statusColumn": {
                                "AKTIV": "Aktives Mitglied",
                                "PASSIV": "Passives Mitglied"
                            },
                            "phoneNumberColumn": {
                                "MOBILE": "Mobil",
                                "HOME": "Privat",
                                "BUSINESS": "Arbeit",
                                "FAX": "Fax",
                            }
                        },
                        "newMemberButton": {
                            "ariaLabel": "Neuen Nutzer hinzufügen",
                            "title": "Neuer Nutzer"
                        }
                    },
                    "memberForm": {
                        "loadingMessage": "Bitte warten, verfügbare Optionen werden geladen...",
                        "mainHeader": {
                            "new": "Neues Mitglied hinzufügen",
                        },
                        "fields": {
                            "memberNo": {
                                "label": "Mitgliedsnr.",
                                "info": "Wird automatisch vergeben",
                            },
                            "firstName": {
                                "label": "Vorname",
                                "placeholder": "Alex",
                            },
                            "lastName": {
                                "label": "Nachname",
                                "placeholder": "Schulze",
                            },
                            "companyName": {
                                "label": "Firma",
                                "placeholder": "",
                            },
                            "salutation": {
                                "label": "Anrede",
                            },
                            "title": {
                                "label": "Titel",
                                "placeholder": "",
                            },
                            "email": {
                                "label": "E-Mail",
                                "placeholder": "alex.schulze@example.local"
                            },
                            "birthday": {
                                "label": "Geburtsdatum",
                            },
                            "address": {
                                "street": {
                                    "label": "Straße",
                                    "placeholder": "Beispielallee"
                                },
                                "houseNo": {
                                    "label": "Hausnr.",
                                    "placeholder": "42"
                                },
                                "postcode": {
                                    "label": "PLZ",
                                    "placeholder": "61137"
                                },
                                "city": {
                                    "label": "Ort",
                                    "placeholder": "Schöneck"
                                },
                            },
                            "membershipStart": {
                                "label": "Start der Mitgliedschaft",
                                "placeholder": ""
                            },
                            "membershipStatus": {
                                "label": "Art der Mitgliedschaft",
                            },
                            "phone": {
                                "landline": {
                                    "label": "Festnetz (Privat)",
                                    "placeholder": ""
                                },
                                "business": {
                                    "label": "Festnetz (Geschäftlich)",
                                    "placeholder": ""
                                },
                                "mobile": {
                                    "label": "Mobil",
                                    "placeholder": ""
                                },
                                "fax": {
                                    "label": "Fax",
                                    "placeholder": ""
                                },
                            },
                            "comments": {
                                "general": {
                                    "label": "Generelle Bemerkungen",
                                    "placeholder": ""
                                },
                                "availability": {
                                    "label": "Vermerke zu Einsatzzeiten bei aktiven Mitgliedern",
                                    "placeholder": ""
                                },
                            },

                        },
                        "buttons": {
                            "cancel": {
                                "ariaLabel": "Eingaben verwerfen und zurück zur Übersicht",
                                "title": "Zurück",
                            },
                            "submit": {
                                "ariaLabel": "Eingegebene Daten speichern",
                                "title": "Speichern",
                            }
                        },
                        "sections": {
                            "personalInfo": {
                                "heading": "Persönliche Informationen",
                                "text": "Die Basisinformationen des Mitglieds"
                            },
                            "addresses": {
                                "heading": "Adressen",
                                "text": "Anschriften des Mitglieds"
                            },
                            "membership": {
                                "heading": "Mitgliedschaft",
                                "text": "Informationen zur Mitgliedschaft"
                            },
                            "contact": {
                                "heading": "Kontaktinformationen",
                                "text": "Informationen, wie wir das Mitglied erreichen können."
                            },
                            "comments": {
                                "heading": "Bemerkungen",
                                "text": "Generelle Vermerke und Bemerkungen"
                            },
                        },
                        "errors": {
                            "unknown-firstName-issue": "Bitte Vornamen überprüfen",
                            "unknown-lastName-issue": "Bitte Nachnamen überprüfen",
                            "unknown-company-name-issue": "Bitte Firmennamen überprüfen",
                            "unknown-birthday-issue": "Bitte Geburtsdatum prüfen",
                            "unknown-address-details-error": "Bitte Adressdaten prüfen",
                            "unknown-address-street-error": "Bitte Straße prüfen",
                            "unknown-address-house-no-error": "Bitte Hausnummer prüfen",
                            "unknown-address-postcode-error": "Bitte Postleitzahl prüfen",
                            "unknown-address-city-error": "Bitte Ort prüfen",
                            "unknown-email-error": "Bitte E-Mail-Adresse prüfen",
                            "unknown-phone-landline-error": "Bitte Festnetz (privat) prüfen",
                            "unknown-phone-business-error": "Bitte Festnetz (geschäftlich) prüfen",
                            "unknown-phone-mobile-error": "Bitte Mobilfunknummer prüfen",
                            "unknown-phone-fax-error": "Bitte Faxnummer prüfen",
                            "unknown-membership-start-error": "Bitte Mitglieds-Startdatum prüfen",
                            "unknown-membership-status-error": "Bitte Mitgliedsstatus prüfen",
                            "address-street-required": "Bitte geben Sie einen Straßennamen an.",
                            "address-postcode-invalid-format": "Bitte eine deutsche Postleitzahl angeben",
                            "address-fields-required": "Wenn eine Adresse angegeben wird, dann bitte alle Felder ausfüllen",
                            "address-incomplete": "Die Adressdaten sind unvollständig",
                            "invalid-landline": "Ungültige Festnetznummer (privat)",
                            "invalid-business": "Ungültige Festnetznummer (geschäftlich)",
                            "invalid-mobile": "Ungültige Mobilfunknummer",
                            "invalid-fax": "Ungültige Faxnummer",
                            "invalid-id": "Ungültige Entitäten-ID",
                            "invalid-email-address": "Ungültige E-Mail-Adresse",
                            "invalid-membership-status": "Ungültiger Mitgliedsstatus",
                            "invalid-phone-numbers": "Bitte Telefonnummern überprüfen",
                            "invalid-birthday-date-format": "Bitte das Geburtsdatum prüfen",
                            "invalid-membership-start-date-format": "Bitte das Startdatum der Mitgliedschaft prüfen",
                            "missing-id": "Entitäten-ID fehlt",
                            "first-name-required": "Bitte einen Vornamen angeben, wenn es nicht um eine Firma handelt",
                            "last-name-required": "Bitte einen Nachname angeben, wenn es nicht um eine Firma handelt",
                            "membership-status-required": "Bitte einen Mitgliedsstatus auswählen",
                            "company-name-required-if-no-full-name-given": "Es muss ein Firmenname angegeben werden, wenn kein Vor- und Nachname angegeben wird.",
                            "company-name-required": "Firmenname ist notwendig, wenn kein voller Name angegeben wird.",
                            "at-least-full-name-or-company": "Es muss mindestens Vor- & Nachname oder ein Firmenname angegeben werden.",
                            "invalid-salutation": "Bitte Anrede überprüfen",
                            "birthday-needs-to-be-before-today": "Geburtstage dürfen nicht in der Zukunft liegen",
                            "membership-must-start-after-birth": "Das Startdatum der Mitgliedschaft muss nach der Geburt liegen",
                        },
                    },
                    "memberDetails": {
                        "heading": "Mitgliederinformationen",
                        "companyPrefix": "Firma",
                        "unknownMember": "Unbekanntes Mitglied",
                        "unknown-detail": "unbekannt",
                        "sections": {
                            "main": {
                                "ageQuantifier": "Jahre",
                            },
                            "contacts": {
                                "heading": "Kontaktinformationen",
                            },
                            "membership": {
                                "heading": "Mitgliedschaft",
                                "memberNo": "Mitgliedsnummer",
                                "memberSince": "Mitglied seit",
                                "status": "Status",
                                "points": "Punkte",
                            },
                            "comments": {
                                "heading": "Amerkungen",
                                "general": "Generelle Bemerkungen",
                                "availability": "Verfügbarkeitsbemerkungen",
                                "noComments": "Keine Bemerkungen vorhanden"
                            },
                        },
                        "buttons": {
                            "delete": {
                                "text": "Löschen",
                                "ariaLabel": "Mitglied löschen",
                            },
                            "back": {
                                "text": "Zurück",
                                "ariaLabel": "Navigiere zurück zur Mitgliederübersicht",
                            },
                        }
                    },
                    "orderList": {
                        "mainHeader": "Auftragsverwaltung",
                        "companyPrefix": "Firma",
                        "table": {
                            "headers": {
                                "orderNo": "Auftragsnr.",
                                "date": "Datum",
                                "member": "Auftraggeber",
                                "helper": "Helfer",
                                "service": "Service",
                                "comments": "Bemerkungen",
                                "status": "Status"
                            },
                            "statusColumn": {
                                "open": "Offen",
                                "done": "Erledigt",
                                "canceled": "Storniert"
                            }
                        },
                        "newOrderButton": {
                            "ariaLabel": "Neuen Auftrag hinzufügen",
                            "title": "Neuer Auftrag"
                        }
                    },
                    "orderDetails": {
                        "sections": {
                            "meta": {
                                "heading": "Auftragsinformationen",
                                "comments": "Bemerkungen",
                                "noComments": "Keine Bemerkungen vorhanden",
                                "submissionChannel": "Übermittlungsweg",
                                "service": "Dienstleistung",
                                "orderDate": "beauftragt zum",
                            },
                            "items": {
                                "heading": "Tätigkeitsübersicht"
                            }
                        },
                    },
                    "serverError": {
                        "response-code": "Der Server hat unerwartet mit dem Status {{statusCode}} geantwortet."
                    },
                }
            }
        }

    })

export default i18n;
