import * as Yup from 'yup'

export function optionalString() {
    return Yup.string()
        .transform(emptyStringToUndefined)
        .defined()
        .optional()
}

export function optionalGermanPhoneNumberString(errorKey: string = "errors.invalid-phone-number") {
    return Yup.string()
        .transform(value => value === '' ? undefined : value.replace(/\s+/g, ''))
        .matches(
            /^(\+49|0)[1-9]\d{1,14}$/,
            errorKey
        )
        .defined()
        .optional()
}

export const emptyStringToUndefined = (value: string) =>
    value === '' ? undefined : value
