import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import {formatDate, parseISO} from "date-fns";
import {isDefined} from "@/helpers/sanitizer.ts";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDateString(date?: string) {
  if (!isDefined(date)) {
    return ""
  }
  const dateStr = parseISO(date!!)
  return formatDate(dateStr, "dd.MM.yyyy")
}
