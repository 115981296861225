import {ColumnDef} from "@tanstack/react-table";
import {sortableColumnHeader} from "@/components/data-table.tsx";
import {Link} from "@/components/tailwind/link.tsx";
import {FilePenLineIcon} from "lucide-react";
import {OrderOverviewItem} from "@/models/order.ts";
import {format, parseISO} from "date-fns";
import {Text} from "@/components/tailwind/text.tsx";
import {Trans} from "react-i18next";

function formatDate(dateString: string) {
    return format(parseISO(dateString), 'dd.MM.yyyy')
}

export const orderColumns: ColumnDef<OrderOverviewItem>[] = [
    {
        accessorKey: "orderNo",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.orderNo")
    },
    {
        accessorKey: "orderDate",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.date"),
        cell: ({row}) => {
            return (
                <Text>
                    {formatDate(row.original.orderDate)}
                </Text>
            )
        }
    },
    {
        accessorKey: "memberName",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.member")
    },
    {
        accessorKey: "service",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.service")
    },
    {
        accessorKey: "helperName",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.helper")
    },
    {
        accessorKey: "comment",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.comments")
    },
    {
        accessorKey: "status",
        header: ({column}) =>
            sortableColumnHeader(column, "orderList.table.headers.status"),
        cell: ({row}) => {
            return (<Trans i18nKey={`orderList.table.statusColumn.${row.original.status}`}/>)
        }
    },
    {
        id: "actions",
        cell: ({row}) => {
            const order = row.original

            return (
                <Link href={`/orders/${order.id}`}><FilePenLineIcon/></Link>

            )
        }

    }
]
